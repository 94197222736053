import differenceInSeconds from "date-fns/differenceInSeconds";
import { eraseCookie, getCookie, setCookie } from "helpers/cookies";
import userManager, { signinSilent, signoutRedirect, signinRedirect } from "utils/oidcClientExtension";
import { history } from "router/history";

userManager.events.addUserSignedOut(async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    history.push('/signout-oidc');
});

//Returns promise to notify the parent window of response from the authorization endpoint.
userManager.signinSilentCallback();

let refreshedAt;
let tokenExpirationInSeconds;
let isRefreshing = false;

const setToken = (data) => {
    setLocalToken(data.access_token);
};

const isLoggedIn = () => {
    return !!getLocalToken();
};

const logout = async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    await signoutRedirect();
};

const onLoginRedirect =() => signinRedirect();

const getLocalToken = () => getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
const setLocalToken = (token) =>
    setCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME, token);


const refreshToken = async () => {
    var user = await signinSilent();
    setToken(user);
    return user;
};

const refreshThrottleIsExpired = () =>
    differenceInSeconds(Date.now(), refreshedAt) > tokenExpirationInSeconds / 2;

const tryRefreshToken = async () => {

    if (!isLoggedIn()) {
        return;
    }

    if (!isRefreshing && (refreshThrottleIsExpired() || !refreshedAt)) {
        isRefreshing = true;
        try {
            const { expires_at } = await refreshToken();
            tokenExpirationInSeconds = expires_at;
            refreshedAt = new Date(Date.now());
        } catch {
            await logout();
        } finally {
            isRefreshing = false;
        }
    }
};

export const authenticationService = {
    setToken,
    logout,
    tryRefreshToken,
    isLoggedIn,
    onLoginRedirect
};
