import { useEffect } from "react";

export const useOnBeforeUnload = function (callback) {
    const handleUnload = (e) => {
        callback && callback();

        // If you prevent default behaviour in Mozilla Firefox prompt will always be shown
        e.preventDefault();

        // Chrome requires returnValue to be set
        e.returnValue = "";
    };

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        };
    });
};
