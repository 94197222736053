import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { accountService } from "services/account";
import { authenticationService } from "services/authentication";

export function ProtectedRoute({ component: Component, ...rest }) {
  const {
    authenticated,
    setAuthenticated,
    userMustChangePinCode,
    setUserMustChangePinCode,
  } = useContext(UserContext);

  const isAuthenticated = authenticationService.isLoggedIn();
  const doesUserMustChangePinCode = accountService.userMustChangePinCode();
  const shouldRedirectToChangePin = doesUserMustChangePinCode && "/change-pin" !== rest.path;

  // trigger on component mount, ensure correct authenticated state
  useEffect(() => {
    if (authenticated && !isAuthenticated) {
      setAuthenticated(false);
    }
    if (userMustChangePinCode && !doesUserMustChangePinCode) {
      setUserMustChangePinCode(false);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          shouldRedirectToChangePin ? (
            <Redirect to="/change-pin" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/login"/>
        )
      }
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
};
