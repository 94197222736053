import { UserManager } from 'oidc-client';
import { IDENTITY_CONFIG } from './identityConfig';

const userManager = new UserManager(IDENTITY_CONFIG);

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export async  function signinSilent () {
  const user = await userManager.signinSilent();
 return user;
};

export function signinSilentCallback () {
  userManager.signinSilentCallback();
};


export async function signoutRedirect() {
  const user = await userManager.getUser();
  return userManager.signoutRedirect({id_token_hint : user && user.id_token})
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager